.animate-gradient {
  animation: 8s ease 0s infinite gradients;
  background: linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%);
}

@keyframes gradients {
  0% {
    background: linear-gradient(to bottom right, #9B24DC 0%,#1DB954 100%),
                linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%);
  }
  20% {
    background: linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%),
                linear-gradient(to bottom right, #9B24DC 0%,#1DB954 100%);
  }
  40% {
    background: linear-gradient(to bottom right, #9B24DC 0%,#1DB954 100%),
                linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%);
  }
  60% {
    background: linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%),
                linear-gradient(to bottom right, #9B24DC 0%,#1DB954 100%);
  }
  80% {
    background: linear-gradient(to bottom right, #9B24DC 0%,#1DB954 100%),
                linear-gradient(to bottom right, #1DB954 0%,#9B24DC 100%);
  }
}
.stacked-linear {
  background-image: linear-gradient(
      217deg,
      rgba(29,185,84,0.8),
      rgba(255, 0, 0, 0) 70.71%
    ), 
    linear-gradient(127deg, rgba(155,36,220, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(155,36,220, 0.8), rgba(0, 0, 255, 0) 70.71%);
}